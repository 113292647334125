import React from 'react'
import HomePage from '../pages/HomePage'
import Header from './Header'



function Layout() {
  

  return (
    <div>
      <Header/>
        <HomePage/>
    </div>
  )
}

export default Layout